import { useNavigate } from '@remix-run/react';

import {
  BrandEditor,
  type BrandFormData,
} from '../components/Brand/BrandEditor';
import { apiService } from '../services/api-service';
import { toMediaDataDTO } from '../utils/api-dto';

function AdminBrandCreate() {
  const navigate = useNavigate();

  const handleConfirm = async (data: BrandFormData) => {
    const resp = await apiService.brand.create({
      name: data.name,
      showcaseMediaData: toMediaDataDTO(data.showcaseMedia.data),
      showcaseText: data.showcaseText,
      blockLengthSec: data.blockLengthSec,
      predefinedBlockData: data.predefinedBlocks.map((item) => ({
        id: item.block.id,
        scenario: item.scenario,
      })),
      tags: data.tags.map((tag) => tag.name),
      blockIds: data.otherBlocks.map((block) => block.id),
    });
    navigate(`/admin/brands/${resp.data.brand.id}`);
  };

  const handleCancel = () => {
    navigate('/admin/brands');
  };

  return (
    <BrandEditor
      defaultValues={{
        name: '',
        showcaseMedia: {
          media: null,
          data: null,
        },
        showcaseText: '',
        blockLengthSec: 25,
        tags: [],
        predefinedBlocks: [],
        otherBlocks: [],
      }}
      action='create'
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    />
  );
}

export const Component = AdminBrandCreate;
